<template>
    <button class="button is-small is-success"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="markEnquiryAsSuccess()">
        Success
    </button>
</template>

<script>
    export default {
        name: 'ButtonMarkAsSuccess',
        props: {
            enquiry: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isLoading: false
            }
        },
        methods: {
            async markEnquiryAsSuccess() {
                let status = 'success'
                if (this.enquiry.status === 'success') {
                    status = 'pending'
                }
                this.isLoading = true
                try {
                    await this.$store.dispatch('mall/updateCampaignEnquiryStatus', {
                        campaignId: this.enquiry.campaign._id,
                        enquiryId: this.enquiry._id,
                        status
                    })
                } catch (e) {
                    console.error(e)
                    this.isLoading = false
                    return
                }
                this.isLoading = false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
