<template>
    <div class="columns">
        <div class="column">
            <div class="card">
                <header class="card-header">
                    <p class="card-header-title">
                        UTM Parameters
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <h6 class="title is-6 is-marginless">Source</h6>
                        <p v-html="utmSource"></p>

                        <h6 class="title is-6 is-marginless">Medium</h6>
                        <p v-html="utmMedium"></p>

                        <h6 class="title is-6 is-marginless">Campaign</h6>
                        <p v-html="utmCampaign"></p>

                        <h6 class="title is-6 is-marginless">Term</h6>
                        <p v-html="utmTerm"></p>

                        <h6 class="title is-6 is-marginless">Content</h6>
                        <p v-html="utmContent"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">

        </div>
    </div>
</template>

<script>
export default {
    name: "EnquiryDetails",
    props: {
        enquiry: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        utmSource () {
            return this.enquiry?.utm?.source ?? "<i>No UTM source detected</i>"
        },
        utmMedium () {
            return this.enquiry?.utm?.medium ?? "<i>No UTM medium detected</i>"
        },
        utmCampaign () {
            return this.enquiry?.utm?.campaign ?? "<i>No UTM campaign detected</i>"
        },
        utmTerm () {
            return this.enquiry?.utm?.term ?? "<i>No UTM term detected</i>";
        },
        utmContent () {
            return this.enquiry?.utm?.content ?? "<i>No UTM content detected</i>";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
