<template>
    <b-table class="enquiry-table"
             detailed
             detail-key="_id"
             v-bind:opened-detailed="openedRow"
             v-bind:data="data"
             v-bind:row-class="(row, index) => rowStatusMap[row.status]">
        <template>
            <b-table-column v-slot="{ row }"
                            field="createdAt"
                            label="Created at"
                            sortable>
                {{ row.createdAt | dateFnsFormatter("d/MMM/yyyy hh:mm a") }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="name"
                            label="Name"
                            sortable>
                {{ row.name }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="gender"
                            label="Gender"
                            sortable>
                {{ row.gender }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="email"
                            label="Email"
                            sortable>
                {{ row.email }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="phone"
                            label="Phone"
                            sortable>
                ({{ row.countryCode }}) {{ row.phone }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="campaign"
                            label="Campaign"
                            sortable>
                {{ row.campaign.title["en"] }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="preferredDate"
                            label="Preferred Date"
                            sortable>
                {{ row.preferredDate | dateFnsFormatter("d/MMM/yyyy") }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field="preferredSlot"
                            label="Preferred Slot"
                            sortable>
                {{ row.preferredSlot }}
            </b-table-column>

            <b-table-column v-slot="{ row }"
                            field=""
                            label="Mark as">
                <p class="buttons">
                    <button-mark-as-contacted v-bind:enquiry="row"></button-mark-as-contacted>
                    <button-mark-as-success v-bind:enquiry="row"></button-mark-as-success>
                    <button-mark-as-failed v-bind:enquiry="row"></button-mark-as-failed>
                </p>
            </b-table-column>
        </template>

        <template v-slot:detail="{ row }">
            <enquiry-details v-bind:enquiry="row"></enquiry-details>
        </template>
    </b-table>
</template>

<script>
import ButtonMarkAsContacted from "../ButtonMarkAsContacted";
import ButtonMarkAsSuccess from "../ButtonMarkAsSuccess";
import ButtonMarkAsFailed from "../ButtonMarkAsFailed";
import EnquiryDetails from "./EnquiryTable/EnquiryDetails";

export default {
    name: "EnquiryTable",
    components: {
        ButtonMarkAsFailed,
        ButtonMarkAsSuccess,
        ButtonMarkAsContacted,
        EnquiryDetails,
    },
    props: {
       data: {
           type: Array,
       },
    },
    data () {
        return {
            rowStatusMap: {
                "pending": "is-white",
                "contacted": "is-warning",
                "success": "is-success",
                "failure": "is-black"
            },
        };
    },
    computed: {
        openedRow () {
            let rows = []
            if (this.$route.params.enquiryId) {
                rows.push(this.$route.params.enquiryId);
            }
            return rows;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
