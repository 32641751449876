<template>
    <section class="enquiries">
        <tool-bar>
            Enquiries
            <template slot="buttons">
                <div class="level-item">
                    <b-pagination simple
                                  order="is-centered"
                                  v-bind:total="enquiryTotal"
                                  v-bind:per-page="perPage"
                                  v-model="currentPage">
                    </b-pagination>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': isLoading }"
                            v-on:click="refreshEnquiries">
                        <span class="icon is-small">
                            <font-awesome-icon icon="redo-alt"></font-awesome-icon>
                        </span>
                        <span>Refresh</span>
                    </button>
                </div>
            </template>
        </tool-bar>
        <enquiry-table v-bind:data="paginatedEnquiries"></enquiry-table>
    </section>
</template>

<script>
import ToolBar from "../layouts/ToolBar";
import EnquiryTable from "./Enquiries/EnquiryTable";

export default {
    name: "Enquiries",
    components: {
        ToolBar,
        EnquiryTable,
    },
    data() {
        return {
            isLoading: false,
            perPage: 20,
            currentPage: 1,
        }
    },
    computed: {
        campaigns () {
            return this.$store.state.mall.campaigns
        },
        enquiries () {
            console.log(this.currentPage);

            if (this.campaigns) {
                return this.campaigns
                    .reduce(
                        (acc, campaign) => acc.concat(
                            campaign.enquiries.map(
                                enquiry => {
                                    enquiry.campaign = campaign
                                    return enquiry
                                }
                            )
                        ),
                        []
                    )
                    .sort(
                        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                    );
            }
            return []
        },
        enquiryTotal () {
            return this.enquiries.length;
        },
        paginatedEnquiries () {
            return this.enquiries
                .slice(
                    (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage
                );
        },
    },
    methods: {
        async refreshEnquiries() {
            this.isLoading = true
            try {
                await this.$store.dispatch(
                    "mall/loadCampaigns"
                );
            } catch (e) {
                this.isLoading = false
                return
            }
            this.isLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.enquiries {

}
</style>

<style lang="scss">
.enquiries {
    .enquiry-table {
        tr {
            &.is-light {
                background-color: #f5f5f5;
                color: #363636;
            }

            &.is-dark {
                background-color: #363636;
                color: #f5f5f5;
            }

            &.is-white {
                background-color: #fff;
                color: #0a0a0a;
            }

            &.is-black {
                background-color: #0a0a0a;
                color: #fff;
            }

            &.is-warning {
                background-color: #ffdd57;
                color: rgba(0, 0, 0, .7);
            }

            &.is-success {
                background-color: #23d160;
                color: #fff;
            }

            strong {
                color: inherit;
            }
        }
    }
}
</style>
